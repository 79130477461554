import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';

import Card from '../components/Card';
import CartCard from '../components/cart/CartCard';
import CartSummary from '../components/cart/CartSummary';
import PromotionCard from '../components/cart/PromotionCard';
import { CITY_ID } from '../config/categories';
import AddressActions from '../redux/addresses/AddressActions';
import AuthActions from '../redux/auth/AuthActions';
import CartActions from '../redux/cart/CartActions';
import { ICartItem } from '../redux/cart/CartTypes';
import { CouponActions } from '../redux/coupons/CouponActions';
import OrderActions from '../redux/orders/OrderActions';
import ProductActions from '../redux/products/ProductActions';
import { IProduct } from '../redux/products/ProductTypes';
import { RootState } from '../redux/store';
import { getOpenHoursState } from '../services/open-hours.service';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';

type PromotionPriceType = {
  szolnok: number;
  szolnokItem: number;
  szolnok_higher: number;
  szolnok_higherItem: number;
  szolnok_higher2: number;
  szolnok_higherItem2: number;
  kecskemet: number;
  kecskemetItem: number;
  kecskemet_higher: number;
  kecskemet_higherItem: number;
  kecskemet_higher2: number;
  kecskemet_higherItem2: number;
};

type ExtraPriceType = {
  lactoseFree28: number;
  lactoseFree52: number;
  fitness: number;
};

function CartScreen(reduxProps: CartReduxProps): JSX.Element {
  const { city } = useParams<{ city: string; category: string }>();
  const cityId = CITY_ID.find((e) => e.name === city);
  const [correctCartItems, setCorrectCartItems] = useState<ICartItem[]>();
  const [promotionPrices, setPromotionPrices] = useState<PromotionPriceType>();
  const [promotionItems, setPromotionItems] = useState<IProduct[]>([]);
  const [extraPrices, setExtraPrices] = useState<ExtraPriceType>();
  const [couponData, setCouponData] = useState<{
    name: string;
    discount: number;
  }>();

  const populateServerPrice = async () => {
    return Promise.all(
      reduxProps.cartItems.map(async (e) => {
        const price = await reduxProps.ProductActions.getItemPrice(
          cityId?.id ?? -1,
          e.product,
          0
        ).then((resp) => resp.data.finalPrice);
        const add = e.product.isRedemption ? 50 : 0;
        e.serverPrice = price + add + e.extraPrice / e.quantity;
        return e;
      })
    );
  };

  useEffect(() => {
    reduxProps.OrderActions.fetchPromotionPrices()
      .then((resp) => {
        setPromotionPrices({
          szolnok: resp.data.szolnok,
          szolnokItem: resp.data.szolnokItem,
          szolnok_higher: resp.data.szolnokHigher,
          szolnok_higherItem: resp.data.szolnokHigherItem,
          szolnok_higher2: resp.data.szolnokHigher2,
          szolnok_higherItem2: resp.data.szolnokHigherItem2,
          kecskemet: resp.data.kecskemet,
          kecskemetItem: resp.data.kecskemetItem,
          kecskemet_higher: resp.data.kecskemetHigher,
          kecskemet_higherItem: resp.data.kecskemetHigherItem,
          kecskemet_higher2: resp.data.kecskemetHigher2,
          kecskemet_higherItem2: resp.data.kecskemetHigherItem2,
        });
        setExtraPrices({
          lactoseFree28: resp.data.lactoseFree28,
          lactoseFree52: resp.data.lactoseFree52,
          fitness: resp.data.fitness,
        });
      })
      .catch((error: Error) => {});
  }, [reduxProps.OrderActions]);

  const getPromotionCosts = () => {
    if (city === 'szolnok') {
      return {
        first: promotionPrices?.szolnok,
        second: promotionPrices?.szolnok_higher,
        firstItem: promotionPrices?.szolnokItem,
        secondItem: promotionPrices?.szolnok_higherItem,
        third: promotionPrices?.szolnok_higher2,
        thirdItem: promotionPrices?.szolnok_higherItem2,
      };
    } else {
      return {
        first: promotionPrices?.kecskemet,
        second: promotionPrices?.kecskemet_higher,
        firstItem: promotionPrices?.kecskemetItem,
        secondItem: promotionPrices?.kecskemet_higherItem,
        third: promotionPrices?.kecskemet_higher2,
        thirdItem: promotionPrices?.kecskemet_higherItem2,
      };
    }
  };

  const promotionCosts = getPromotionCosts();

  useEffect(() => {
    if (reduxProps.productCategories.length === 0) {
      reduxProps.ProductActions.fetchAllCategory();
      reduxProps.ProductActions.fetchAllProduct();
    }
    populateServerPrice().then((arr) => setCorrectCartItems(arr));
    // eslint-disable-next-line
  }, [reduxProps.cartItems, reduxProps.changeHelper]);

  const handleAdd = (product: IProduct) => {
    reduxProps.CartActions.addQuantity(product);
  };
  const handleSub = (product: IProduct) => {
    reduxProps.CartActions.subtractQuantity(product);
  };
  const allCategoryFlatted = [...reduxProps.productCategories]
    .flatMap((i) => [i, ...i.childCategories])
    .flatMap((i) => [i, ...i.childCategories])
    .flatMap((i) => [i, ...i.childCategories])
    .flatMap((i) => [i, ...i.childCategories]);
  const emptyCart = () => {
    reduxProps.CartActions.emptyCart();
  };

  const [openHoursSate] = useState(getOpenHoursState());

  const initialPrice = correctCartItems?.reduce(
    (acc, { serverPrice, quantity }) => acc + (serverPrice ?? 0) * quantity,
    0
  );

  const promoItemCat1 = () =>
    allCategoryFlatted.find((c) => {
      const parentCat = c.items.find((i) => i.id === promotionCosts.firstItem);
      return parentCat;
    });
  const promoItemCat2 = () =>
    allCategoryFlatted.find((c) => {
      const parentCat = c.items.find((i) => i.id === promotionCosts.secondItem);
      return parentCat;
    });

  const promoItemCat3 = () =>
    allCategoryFlatted.find((c) => {
      const parentCat = c.items.find((i) => i.id === promotionCosts.thirdItem);
      return parentCat;
    });

  const promoItems = {
    first: promoItemCat1()?.items.find(
      (i) => i.id === promotionCosts.firstItem
    ),
    second: promoItemCat2()?.items.find(
      (i) => i.id === promotionCosts.secondItem
    ),
    third: promoItemCat3()?.items.find(
      (i) => i.id === promotionCosts.thirdItem
    ),
  };

  useEffect(() => {
    if (initialPrice <= promotionCosts.first) {
      const item = correctCartItems.find(
        (item) => item.product.id === promoItems?.first?.id
      );

      if (item) {
        reduxProps.CartActions.removeItem(item.product);
      }
    }
    if (initialPrice <= promotionCosts.second) {
      const item = correctCartItems.find(
        (item) => item.product.id === promoItems?.second?.id
      );

      if (item) {
        reduxProps.CartActions.removeItem(item.product);
      }
    }
    if (initialPrice <= promotionCosts.third) {
      const item = correctCartItems.find(
        (item) => item.product.id === promoItems?.third?.id
      );

      if (item) {
        reduxProps.CartActions.removeItem(item.product);
      }
    }

    let arr = [];
    reduxProps.cartItems.forEach((item) => {
      if (item?.product?.id === promoItems?.first?.id) {
        arr.push(promoItems.first);
      }
      if (item?.product?.id === promoItems?.second?.id) {
        arr.push(promoItems.second);
      }
      if (item?.product?.id === promoItems?.third?.id) {
        arr.push(promoItems.third);
      }
    });

    setPromotionItems(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxProps.cartItems, correctCartItems]);

  return (
    <>
      <Navbar />
      <Box as='main' color='black'>
        <Card
          p={1}
          ml={8}
          w='200px'
          borderRadius='8px 8px 0 0'
          textAlign='center'
          textTransform='uppercase'
          fontWeight='bold'
        >
          Kosár
        </Card>
        <Card>
          <Grid gridTemplateColumns={['1fr', '1fr', '1fr', '6fr 4fr']} gap={4}>
            <Grid
              gridTemplateColumns='1fr 4fr 4fr 2.5fr 2.5fr'
              gridTemplateRows='20px auto'
              justifyItems='center'
              alignContent='start'
              gap={4}
              position='relative'
            >
              <Grid
                gridArea='1 / 1 / 3 / 6'
                gridTemplateColumns={[
                  '1fr 4fr 2.5fr 2.5fr',
                  '1fr 3fr 4fr 2.5fr 2.5fr',
                ]}
                justifyItems='center'
                alignContent='start'
                gap={4}
                position='relative'
                padding='10px 6px'
              >
                <Heading as='h4' size='sm' gridArea='1 / 1 / 2 / 2' h='20px'>
                  {reduxProps.cartItems.length > 0 && (
                    <Button
                      onClick={emptyCart}
                      gridColumn={['colStart', 'col2']}
                      variantColor='red'
                      size='xs'
                    >
                      Kosár ürítése
                    </Button>
                  )}
                </Heading>
                <Heading
                  as='h4'
                  size='sm'
                  gridArea={['1 / 2 / 2 / 3', '1 / 3 / 2 / 4']}
                  h='20px'
                >
                  Termék
                </Heading>
                <Heading
                  as='h4'
                  size='sm'
                  gridArea={['1 / 3 / 2 / 4', '1 / 4 / 2 / 5']}
                  h='20px'
                >
                  Mennyiség
                </Heading>
                <Heading
                  as='h5'
                  size='sm'
                  gridArea={['1 / 4 / 2 / 5', '1 / 5 / 2 / 6']}
                  h='20px'
                >
                  Ár/db
                </Heading>
              </Grid>

              <SimpleGrid columns={1} spacing={1} gridColumn='1 / 6' w='100%'>
                {openHoursSate.warningState === 'warning' && (
                  <Alert status='warning' w='100%'>
                    <AlertIcon />
                    <Box flex='1'>
                      <AlertTitle mr={2}>Éttermünk hamarosan bezár!</AlertTitle>
                      <AlertDescription>
                        A rendelésed még {openHoursSate.timeLeft} percig adhatod
                        le. Zárás után az időpontra leadott rendelést fogadja be
                        a rendszer.
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}
                {openHoursSate.orderEnabled === false && (
                  <Alert status='error' w='100%'>
                    <AlertIcon />
                    <Box flex='1'>
                      <AlertTitle mr={2}>
                        Az étterem jelenleg zárva van!
                      </AlertTitle>
                      <AlertDescription>
                        Látogass vissza az oldalra nyitás után vagy kérd a
                        rendelésedet időpontra!
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}
              </SimpleGrid>

              {correctCartItems?.map((e) => (
                <CartCard
                  key={
                    e.product.id +
                    JSON.stringify(e.product.contents) +
                    e.extraPrice
                  }
                  item={e}
                  allCategoryFlatted={allCategoryFlatted}
                  parentCategory={allCategoryFlatted.find(
                    (pc) => pc.id === e.product.parentCategoryId
                  )}
                  addAction={handleAdd}
                  subAction={handleSub}
                  extraPrices={extraPrices}
                  promotionItems={promotionItems}
                />
              ))}

              {!promotionItems.find(
                (item) => item?.id === promoItems?.first?.id
              ) &&
                promotionCosts.first !== 0 &&
                initialPrice >= promotionCosts.first && (
                  <PromotionCard
                    item={promoItems.first}
                    cartActions={reduxProps.CartActions}
                  />
                )}
              {!promotionItems.find(
                (item) => item?.id === promoItems?.second?.id
              ) &&
                promotionCosts.second !== 0 &&
                initialPrice >= promotionCosts.second && (
                  <PromotionCard
                    item={promoItems.second}
                    cartActions={reduxProps.CartActions}
                  />
                )}
              {!promotionItems.find(
                (item) => item?.id === promoItems?.third?.id
              ) &&
                promotionCosts.third !== 0 &&
                initialPrice >= promotionCosts.third && (
                  <PromotionCard
                    item={promoItems.third}
                    cartActions={reduxProps.CartActions}
                  />
                )}

              {promotionCosts.first !== 0 &&
                initialPrice <= promotionCosts.first && (
                  <Card
                    gridColumn='1 / 6'
                    p='10px 6px'
                    h='fit-content'
                    w='100%'
                    bg='red.500'
                  >
                    <Flex
                      w='100%'
                      justify='center'
                      alignItems='center'
                      px={[8, 16]}
                    >
                      <Heading fontSize='sm' color='white' textAlign='center'>
                        {`Neked szóló promóciós ajánlathoz ${
                          promotionCosts?.first - initialPrice
                        } Ft összegért kell még rendelned!`}
                      </Heading>
                    </Flex>
                  </Card>
                )}

              {promotionCosts.second !== 0 &&
                initialPrice >= promotionCosts.first &&
                initialPrice <= promotionCosts.second && (
                  <Card
                    gridColumn='1 / 6'
                    p='10px 6px'
                    h='fit-content'
                    w='100%'
                    bg='red.500'
                  >
                    <Flex
                      w='100%'
                      justify='center'
                      alignItems='center'
                      px={[8, 16]}
                    >
                      <Heading fontSize='sm' color='white' textAlign='center'>
                        {`Még ${
                          promotionCosts?.second - initialPrice
                        } Forint hiányzik a következő promóciós termék feloldásához!`}
                      </Heading>
                    </Flex>
                  </Card>
                )}

              {promotionCosts.third !== 0 &&
                initialPrice >= promotionCosts.first &&
                initialPrice >= promotionCosts.second &&
                initialPrice <= promotionCosts.third && (
                  <Card
                    gridColumn='1 / 6'
                    p='10px 6px'
                    h='fit-content'
                    w='100%'
                    bg='red.500'
                  >
                    <Flex
                      w='100%'
                      justify='center'
                      alignItems='center'
                      px={[8, 16]}
                    >
                      <Heading fontSize='sm' color='white' textAlign='center'>
                        {`Még ${
                          promotionCosts?.third - initialPrice
                        } Forint hiányzik a következő promóciós termék feloldásához!`}
                      </Heading>
                    </Flex>
                  </Card>
                )}

              {!correctCartItems &&
                reduxProps.cartItems?.map((_, idx) => (
                  <Card
                    key={idx}
                    gridColumn='1 / 6'
                    p='10px 6px'
                    h='fit-content'
                    w='100%'
                  >
                    <Grid
                      gridTemplateColumns='1fr 4fr 4fr 2.5fr 2.5fr'
                      my='auto'
                      justifyItems='center'
                      justifyContent='center'
                      alignItems='center'
                      gap={4}
                    >
                      <Flex direction='column' />
                      <Image
                        maxH={100}
                        w='auto'
                        borderRadius='md'
                        fallbackSrc='https://via.placeholder.com/150'
                      />
                      <Text fontSize='sm' textAlign='center'>
                        Töltés...
                      </Text>
                      <Text fontSize='sm' textAlign='center'>
                        Töltés...
                      </Text>
                      <Text fontSize='sm' textAlign='center'>
                        Töltés...
                      </Text>
                    </Grid>
                  </Card>
                ))}

              {/**     <Image
              src={logo}
              gridColumn='1 / 6'
              display={['none', 'none', 'none', 'inherit']}
              zIndex={0}
              h='165px'
            />*/}
            </Grid>

            <CartSummary
              reduxProps={reduxProps}
              couponData={couponData}
              onCouponDataChange={(data) => setCouponData(data)}
              correctCartItems={correctCartItems ?? []}
            />
          </Grid>
        </Card>
      </Box>
      <Footer />
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    cartItems: state.cartReducer.cartItems,
    changeHelper: state.cartReducer.changeHelper,
    productCategories: state.productReducer.categories,
    userIsLoggedIn: state.authReducer.isLoggedIn,
    userInfo: state.authReducer.userInfo,
    localAddresses: state.addressReducer.addresses,
    usedCoupon: state.couponReducer.usedCoupon,
    userFormData: state.cartReducer.userFormData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CartActions: CartActions(dispatch),
    AuthActions: AuthActions(dispatch),
    AddressActions: AddressActions(dispatch),
    OrderActions: OrderActions(dispatch),
    ProductActions: ProductActions(dispatch),
    couponActions: CouponActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CartReduxProps = ConnectedProps<typeof connector>;
export default connector(CartScreen);
