import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Grid,
  Input,
  InputGroup,
  Select,
  useToast,
} from '@chakra-ui/core';
import React, { FormEvent, useEffect, useState } from 'react';

import { ALLERGIES } from '../../config/categories';
import { RegisterFormData } from '../../redux/auth/AuthTypes';
import Card from '../Card';
import { AuthWhatsOpen, SetAuthWhatsOpenProps } from '../home/HomeConstants';
import { authConnector, ReduxNavProps } from '../navbar/NavConstants';

const defaultFormData: RegisterFormData = {
  fullName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  birthday: '01/1',
  allergies: [],
};

function ModifyForm({
  formToModify,
  reduxProps,
  setwhatsOpen,
  ...props
}: SetAuthWhatsOpenProps & {
  reduxProps: ReduxNavProps;
  formToModify?: RegisterFormData;
}): JSX.Element {
  const toast = useToast();
  const [formData, setFormData] = useState<RegisterFormData>(
    formToModify || defaultFormData
  );

  const [allergyIds, setAllergyIds] = useState<number[]>([]);

  // Generate select options for days to avoid code redundancy
  const generateDayOptions = (): JSX.Element[] => {
    const optionArray: JSX.Element[] = [];
    for (let i = 1; i < 32; i++) {
      if (i < 10) {
        optionArray.push(
          <option key={i} value={`0${i.toString()}`}>
            {i}.
          </option>
        );
      } else {
        optionArray.push(
          <option key={i} value={i}>
            {i}.
          </option>
        );
      }
    }

    return optionArray;
  };

  // Generate allergy checkboxes
  const generateAllergies = (): JSX.Element => {
    const allergyChkboxes: JSX.Element[] = [];
    ALLERGIES.forEach((allergy) => {
      allergyChkboxes.push(
        <Checkbox
          mr={2}
          isChecked={allergyIds.includes(allergy.id)}
          key={allergy.id}
          value={allergy.id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const array = [...allergyIds];
            if (e.target.checked) {
              // Add that id

              array.push(parseInt(e.target.value, 10));
              setAllergyIds(array);
            } else {
              // Remove that id
              const index = array.indexOf(parseInt(e.target.value, 10));
              if (index !== -1) {
                array.splice(index, 1);
                setAllergyIds(array);
              }
            }
            const allergyData: any[] = array.map((val: number) => {
              return { id: val };
            });
            setFormData({ ...formData, allergies: allergyData });
          }}
        >
          {allergy.name}
        </Checkbox>
      );
    });

    return <Flex wrap='wrap'>{allergyChkboxes}</Flex>;
  };

  let wasError = false;

  function getAllergyIds(userAllergies: any[]) {
    const allIds: number[] = [];
    userAllergies.forEach((allergy) => {
      allIds.push(allergy.id);
    });

    setAllergyIds(allIds);
  }

  useEffect(() => {
    if (formToModify) {
      setFormData(formToModify);
      if (formToModify.allergies) getAllergyIds(formToModify.allergies);
    }
    return () => {};
  }, [reduxProps.userInfo, formToModify]);

  function removeEmptyAttributes(obj: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        // eslint-disable-next-line no-param-reassign
        delete obj[propName];
      }
    }
  }

  async function modify(e: FormEvent) {
    e.preventDefault();
    removeEmptyAttributes(formData);

    const promise = reduxProps.AuthActions.modifyInfo(formData).catch(
      (error) => {
        wasError = true;
        toast({
          title: 'Hiba',
          description: error.response.data.msg,
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
      }
    );

    await promise.then(() => {
      if (setwhatsOpen && !wasError) {
        toast({
          title: 'Siker',
          description: 'Sikeres módosítás!',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        });
        setwhatsOpen(AuthWhatsOpen.about);
        reduxProps.AuthActions.getUserInfo();
      }
    });
  }
  return (
    <Card color='black' maxW='350px' {...props}>
      <FormControl>
        <Grid
          onSubmit={(e) => {
            e.preventDefault();
            modify(e);
          }}
          as='form'
          gridTemplateColumns='[colStart] 1fr 1fr [colEnd]'
          w='100%'
          gridGap={2}
        >
          <Input
            gridColumn='colStart / colEnd'
            type='text'
            placeholder='Teljes név'
            size='sm'
            isRequired
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.fullName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, fullName: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='email'
            placeholder='E-mail cím'
            size='sm'
            isDisabled
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='password'
            placeholder='Jelszó'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='password'
            min={11}
            placeholder='Jelszó újra'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, confirmPassword: e.target.value });
            }}
          />

          <Input
            gridColumn='colStart / colEnd'
            type='tel'
            isRequired
            placeholder='Telefonszám'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, phone: e.target.value });
            }}
          />
          <InputGroup gridColumn='colStart / colEnd'>
            <Select
              isDisabled={reduxProps.userInfo?.birthday !== null}
              value={formData.birthday?.split('/')[0]}
              backgroundColor='gray.300'
              defaultValue={formData.birthday?.split('/')[0]}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFormData({
                  ...formData,
                  birthday: `${formData.birthday.split('/')[0]}/${
                    e.target.value
                  }`,
                });
              }}
              _placeholder={{ color: 'black' }}
            >
              <option value='01'>Január</option>
              <option value='02'>Február</option>
              <option value='03'>Március</option>
              <option value='04'>Április</option>
              <option value='05'>Május</option>
              <option value='06'>Június</option>
              <option value='07'>Július</option>
              <option value='08'>Augusztus</option>
              <option value='09'>Szeptember</option>
              <option value='10'>Október</option>
              <option value='11'>November</option>
              <option value='12'>December</option>
            </Select>
            <Select
              isDisabled={reduxProps.userInfo?.birthday !== null}
              value={formData.birthday.split('/')[1]}
              backgroundColor='gray.300'
              _placeholder={{ color: 'black' }}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFormData({
                  ...formData,
                  birthday: `${e.target.value}/${
                    formData.birthday?.split('/')[1]
                  }`,
                });
              }}
            >
              {generateDayOptions()}
            </Select>
          </InputGroup>
          <Box gridColumn='colStart / colEnd'>
            <CheckboxGroup isInline spacing={8}>
              {generateAllergies()}
            </CheckboxGroup>
          </Box>

          <Box gridColumn='colStart / colEnd' w={[289.22, 286]} />

          <Button
            gridColumn='colStart / colEnd'
            variantColor='brand'
            type='submit'
          >
            Módosítás
          </Button>
        </Grid>
      </FormControl>
    </Card>
  );
}

export default authConnector(ModifyForm);
