export const configFile = {
  serverBaseUrl:
    process.env.REACT_APP_API_URL_CMS_BASE_URL ||
    //'http://localhost:3000/v1/api',
    'https://api.pizzasprint.hu/v1/api',
  serverBaseUrlPicture: `${
    process.env.REACT_APP_API_URL_CMS_BASE_URL_IMG ||
    // 'http://localhost:3000/v1/cms'
    'https://api.pizzasprint.hu/v1/cms'
  }/files/`,
  facebookAppId: process.env.FACEBOOK_APP_ID || 796390604464336,

  MINIMUM_MINS_BETWEEN_SHIPPING: 60,

  cardDisclaimerKecskemet:
    'A "Megrendelem" gomb megnyomásával tudomásul veszem, hogy a(z) Pastaline Kft.(Budapest) adatkezelő által a(z) https://pizzasprint.hu/kecskemet felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft. , mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: Név, Email cím, Telefonszám, Lakcím Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:',
  cardDisclaimerSzolnok:
    'A "Megrendelem" gomb megnyomásával tudomásul veszem, hogy a(z) Forno Kft.(Szolnok) adatkezelő által a(z) https://pizzasprint.hu/szolnok felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft. , mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: Név, Email cím, Telefonszám, Lakcím Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:',
  // Important!
  // Week starts with Sunday (0)
  // and ends with Saturday (6)
  openHours: [
    {
      days: [1, 2, 3, 4], // Mon - Tue,
      from: '9:30',
      to: '22:00',
    },
    {
      days: [5, 6], // Fri - Sat
      from: '9:30',
      to: '23:00',
    },
    {
      days: [0], // Sun
      from: '11:30',
      to: '22:00',
    },
  ],
  openHoursWarningOffset: 30, // minutes before close - show warning
  openHoursCloseOrderOffset: 0, // minutes before close - disable order
};
export const BASE_SOCKET_IO_URL =
  process.env.REACT_APP_API_URL_SOCKETIO_BASE_URL ||
  'https://api.pizzasprint.hu';
