/* eslint-disable no-return-assign */
import {
  Box,
  Flex,
  Image,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  ModalCloseButton,
} from '@chakra-ui/core';
import { css } from '@emotion/core';
import { GiHamburgerMenu } from 'react-icons/gi';
import React, { useState } from 'react';
import {
  generatePath,
  Link as ReactRouterLink,
  useLocation,
} from 'react-router-dom';

import cartIcon from '../../assets/cart.png';
import huLangIcon from '../../assets/lang/hu.png';
import logoIcon from '../../assets/logo.png';
import { AuthWhatsOpenProps } from '../home/HomeConstants';
import ExitPopup from '../popups/ExitPopup';
import PayDeskPopup from '../popups/PayDeskPopup';
import NavDropDown from './DropDown';
import {
  burger,
  menu_left,
  menu_right,
  NavBarIconsProps,
  ReduxNavProps,
  useOpenPopupStore,
  usePopupStore,
} from './NavConstants';
import { NavLink, NavRouterLink } from './NavLinks';

export function NavbarCart({
  reduxProps,
}: {
  reduxProps: ReduxNavProps;
}): JSX.Element {
  const location = useLocation();
  const city = location.pathname.split('/')[1];
  return (
    <Flex alignItems='center'>
      <NavRouterLink
        href={generatePath('/:city/coupons', {
          city,
        })}
        position='relative'
        p={0}
        py='3px'
        px='1rem'
        mx='2rem'
        fontWeight='bold'
        textTransform='uppercase'
        borderRadius='6px'
        backgroundColor='red.500'
      >
        <Text paddingLeft={1} fontSize={18}>
          Kuponok
        </Text>
      </NavRouterLink>

      <NavRouterLink
        href={generatePath('/:city/cart', {
          city,
        })}
        position='relative'
        padding-left='0.33rem'
      >
        <Flex>
          <Image src={cartIcon} w='24px' h='auto' />
          <Text
            backgroundColor='red.500'
            borderRadius='40px'
            position='absolute'
            px='0.33rem'
            left='-4px'
            top='14px'
            fontSize='xs'
          >
            {reduxProps.cartItems.length === 0
              ? ''
              : reduxProps.cartItems.reduce((prev, curr) => {
                  return {
                    product: curr.product,
                    quantity: prev.quantity + curr.quantity,
                    extraPrice: curr.extraPrice,
                  };
                }).quantity}
          </Text>
          <Text paddingLeft={1} fontSize={18}>
            Kosár
          </Text>
        </Flex>
      </NavRouterLink>

      <NavLink display='none'>
        <Image src={huLangIcon} w='24px' h='auto' />
      </NavLink>
    </Flex>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkAsAny = Link as any;

export function NavbarContent({
  setwhatsOpen,
  disclosure,
  reduxProps,
  ...props
}: AuthWhatsOpenProps & { reduxProps: ReduxNavProps }): JSX.Element {
  const location = useLocation();
  // const { city, category } = useParams();
  const city = location.pathname.split('/')[1];

  const [popupOpen, setPopupOpen] = useState(false);

  function close() {
    setPopupOpen(false);
  }

  const newCats = reduxProps?.categories[0]?.childCategories.filter(
    (cat) => cat.name.toLowerCase() === 'hamburger'
  );

  const left =
    city === 'kecskemet' &&
    newCats &&
    newCats[0]?.childCategories[0]?.items.length !== 0
      ? menu_left.concat(burger)
      : menu_left;
  const right = menu_right;

  return (
    <Flex direction='column' {...props}>
      <Box
        position='relative'
        mt={[8, 8, 8, 0]}
        bg='black'
        shadow='2px 2px 5px 0px rgba(0,0,0,0.75)'
        p='10px'
        rounded='lg'
        w='100%'
        h={[null, null, null, '105px']}
        minH={['105px', '105px', '105px', '0px']}
        css={css`
          z-index: 300;
        `}
      >
        <Box
          position='absolute'
          top='32.5px'
          left={0}
          ml={5}
          display={['block', 'block', 'block', 'none']}
        >
          {/* <IconButton
            variant="outline"
            variantColor="red"
            aria-label="Dropdown"
            icon={dropdown ? 'triangle-up' : 'triangle-down'}
            onClick={toggleDropDown}
          />
           */}
          <Flex
            width='50px'
            height='50px'
            alignItems='center'
            justifyContent='center'
          >
            <GiHamburgerMenu size={30} onClick={() => setPopupOpen(true)} />
          </Flex>
        </Box>
        <Box
          position='absolute'
          top='32.5px'
          right={0}
          mr={5}
          display={['block', 'block', 'block', 'none']}
        >
          <NavRouterLink
            href={generatePath('/:city/cart', {
              city,
            })}
            p={0}
            position='relative'
          >
            <Image src={cartIcon} w='40px' h='auto' />
            <Text
              backgroundColor='red.500'
              borderRadius='40px'
              position='absolute'
              px='0.33rem'
              left='16.5px'
              top='10px'
              fontSize='xs'
            >
              {reduxProps.cartItems.length === 0
                ? ''
                : reduxProps.cartItems.reduce((prev, curr) => {
                    return {
                      product: curr.product,
                      quantity: prev.quantity + curr.quantity,
                      extraPrice: curr.extraPrice,
                    };
                  }).quantity}
            </Text>
          </NavRouterLink>
        </Box>
        <Flex direction='row' justify='space-between' height='100%'>
          <NavBarIcons menuItems={left} city={city} />
          <LinkAsAny
            as={ReactRouterLink}
            to={generatePath('/:city', {
              city,
            })}
            position='relative'
            mx={['auto', 'auto', 'auto', '0']}
            top={['-20px', '-20px', '-30px']}
            h={['140px', '130px', '165px']}
            w={['140px', '130px', '165px']}
            minWidth={['130px', '130px', '165px']}
            p={0}
          >
            <Image src={logoIcon} zIndex={310} />
          </LinkAsAny>
          <NavBarIcons menuItems={right} city={city} />
        </Flex>
        <NavBarIcons
          menuItems={[...left, ...right]}
          city={city}
          display={['flex', 'flex', 'flex', 'none']}
          overflow='scroll'
        />
      </Box>
      <Modal isOpen={popupOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent w='full' backgroundColor='transparent'>
          <ModalCloseButton color='white' />
          <NavDropDown
            setwhatsOpen={setwhatsOpen}
            disclosure={disclosure}
            isLoggedIn={reduxProps.isLoggedIn}
            city={city}
            close={close}
            left={left}
            right={right}
          />
        </ModalContent>
      </Modal>
    </Flex>
  );
}

function NavBarIcons({ menuItems, city, ...props }: NavBarIconsProps) {
  return (
    <Flex
      direction='row'
      height='100%'
      w='100%'
      justifyContent={['space-between', 'space-around']}
      display={['none', 'none', 'none', 'flex']}
      {...props}
    >
      {menuItems.map((item) => {
        const path = generatePath(item.path ? item.path : '/:city/:category', {
          city,
          category: item.pathName.toLowerCase(),
        });
        return (
          <NavRouterLink key={item.pathName} p={0} href={path}>
            <Flex
              w='100%'
              direction='column'
              align='center'
              height='100%'
              justifyContent='space-between'
              px={2}
            >
              <Image
                height={['38px', '48px', '48px', '48px', '58px']}
                src={item.icon}
                onMouseOver={(e) => (e.currentTarget.src = item.icon_red)}
                onFocus={(e) => (e.currentTarget.src = item.icon_red)}
                onMouseOut={(e) => (e.currentTarget.src = item.icon)}
                onBlur={(e) => (e.currentTarget.src = item.icon)}
                alt={item.name}
              />
              <Text>{item.name.toUpperCase()}</Text>
            </Flex>
          </NavRouterLink>
        );
      })}
    </Flex>
  );
}

export function PopupModal({
  reduxProps,
}: {
  reduxProps: ReduxNavProps;
}): JSX.Element {
  const [popupWhatsOpen] = usePopupStore();
  const [popupOpen, setPopupOpen] = useOpenPopupStore();

  function close() {
    setPopupOpen(false);
  }

  return (
    <Modal isOpen={popupOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent w='fit-content' px={2} backgroundColor='transparent'>
        <ExitPopup
          reduxProps={reduxProps}
          display={popupWhatsOpen === 'exit' ? 'block' : 'none'}
          close={setPopupOpen}
        />
        <PayDeskPopup
          reduxProps={reduxProps}
          display={popupWhatsOpen === 'order' ? 'block' : 'none'}
          close={setPopupOpen}
        />
      </ModalContent>
    </Modal>
  );
}
