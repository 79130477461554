import 'moment/locale/hu';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Grid,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useNavigate, useParams } from 'react-router-dom';

import { CITY_ID } from '../../config/categories';
import { configFile } from '../../configFile';
import { IAddress } from '../../redux/addresses/AddressTypes';
import { RegisterFormData } from '../../redux/auth/AuthTypes';
import { ICartItem } from '../../redux/cart/CartTypes';
import {
  IOrderForm,
  IOrderShippingMethod,
} from '../../redux/orders/OrderTypes';
import { CartReduxProps } from '../../screens/CartScreen';
import Card from '../Card';
import { useOpenPopupStore, usePopupStore } from '../navbar/NavConstants';
import getTimeConstraints from './dateHelpers';
import { getOpenHoursState } from '../../services/open-hours.service';
import { szolnokData, kecskemetData } from '../szolnokAddresses';
import Autocomplete from '../autocomplete';

const addDays = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

type ShippingCostType = {
  kecskemet: number;
  kecskemet_to_else: number;
  szolnok: number;
  szolnok_to_else: number;
};

const defaultShippCosts: ShippingCostType = {
  kecskemet: 0,
  kecskemet_to_else: 300,
  szolnok: 0,
  szolnok_to_else: 300,
};

const defaultAddressFormData: IAddress = {
  name: '',
  zip: 0,
  city: '',
  street: '',
  number: '',
  floorDoor: '',
  bellComment: '',
};

const defaultFormData: IOrderForm = {
  county: '',
  email: '',
  name: '',
  phone: '',
  comment: '',
  coupon: '',
  bigMoney: false,
  requestInvoice: false,
  isPrivate: false,
  isPrivateName: '',
  billingCompany: '',
  billingTaxNumber: '',
  billingAddress: '',
  shippingDate: moment(),
  shippingMethod: IOrderShippingMethod.Instant,
  usedLoyaltyPoints: 0,
};

type Props = {
  reduxProps: CartReduxProps;
  correctCartItems: ICartItem[];
  couponData?: {
    name: string;
    discount: number;
  };
  onCouponDataChange: (data?: { name: string; discount: number }) => void;
};

function CartSummary({
  reduxProps,
  correctCartItems,
  couponData,
  onCouponDataChange,
}: Props): JSX.Element {
  const { city } = useParams<{ city: string }>();
  const cityId = CITY_ID.find((e) => e.name === city);
  const initialPrice = correctCartItems.reduce(
    (acc, { serverPrice, quantity }) => acc + (serverPrice ?? 0) * quantity,
    0
  );
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);

  const [addressFormData, setAddressFormData] = useState<IAddress>(
    defaultAddressFormData
  );
  const [oldAddressData, setOldAddress] = useState<IAddress>(
    defaultAddressFormData
  );
  const [selectedAddress, setSelectedAddress] = useState<IAddress>(
    defaultAddressFormData
  );
  const [addingAddress, setNewAddress] = useState(false);
  const [modifyAddress, setAddressModification] = useState(false);

  const [formData, setFormData] = useState<IOrderForm>({
    ...defaultFormData,
    county: city === 'szolnok' ? 'Jász-Nagykun-Szolnok' : 'Bács-Kiskun',
    name: reduxProps.userInfo?.name || '',
    email: reduxProps.userInfo?.email || '',
    phone: reduxProps.userInfo?.phone || '',
    // @ts-ignore
    shippingMethod:
      reduxProps.userFormData?.shippingMethod || defaultFormData.shippingMethod,
    // @ts-ignore
    paymentType:
      reduxProps.userFormData?.paymentType || defaultFormData.paymentType,
    comment: reduxProps.userFormData?.comment || defaultFormData.comment,
    bigMoney: reduxProps.userFormData?.bigMoney || defaultFormData.bigMoney,
    requestInvoice:
      reduxProps.userFormData?.requestInvoice || defaultFormData.requestInvoice,
    isPrivate: reduxProps.userFormData?.isPrivate || defaultFormData.isPrivate,
    isPrivateName:
      reduxProps.userFormData?.isPrivateName || defaultFormData.isPrivateName,
    billingCompany:
      reduxProps.userFormData?.billingCompany || defaultFormData.billingCompany,
    billingTaxNumber:
      reduxProps.userFormData?.billingTaxNumber ||
      defaultFormData.billingTaxNumber,
    billingAddress:
      reduxProps.userFormData?.billingAddress || defaultFormData.billingAddress,
    usedLoyaltyPoints: reduxProps.userFormData?.usedLoyaltyPoints,
  });

  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isAfszAccepted, setAszf] = useState(true);
  const [invalidateASZF, setInvalidateAFSZ] = useState(false);

  const [isCardAccepted] = useState(true);
  const [, setInvalidateCardAccepted] = useState(false);

  const router = useNavigate();

  const toast = useToast();
  const showErrorToast = (error: any) =>
    toast({
      title: 'Hiba',
      description: error?.message,
      status: 'error',
      position: 'top',
      duration: 9000,
      isClosable: true,
    });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [wasTriggered, setWasTriggered] = useState(false);
  const [, setPopupWhatsOpen] = usePopupStore();
  const [, setPopupOpen] = useOpenPopupStore();

  const [shippingCosts, setShippingCosts] = useState<ShippingCostType>(
    defaultShippCosts
  );

  const checkCoupon = async () => {
    const coupon = formData.coupon || reduxProps.usedCoupon;
    if (coupon) {
      const { data } = await reduxProps.OrderActions.checkPrice(
        correctCartItems
      );

      const cPrice = data.finalPrice;
      await reduxProps.OrderActions.validateCoupon(correctCartItems, coupon)
        .then((response) => {
          const discount = cPrice - response.finalPrice;
          toast({
            title: 'Siker',
            description: `Kedvezmény: ${discount.toString()} Ft`,
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          });
          onCouponDataChange({ name: response.name, discount });
          reduxProps.couponActions.addCoupon(coupon);
          setLoyaltyPoints(0);
        })
        .catch((error) => {
          toast({
            title: 'Hiba',
            description:
              error?.response?.data?.msg ?? 'Valamilyen hiba történt',
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          });
          onCouponDataChange(undefined);
          setFormData({ ...formData, coupon: '' });
        });
    }
  };

  useEffect(() => {
    const formDataToSave = {
      paymentType: formData.paymentType,
      shippingMethod: formData.shippingMethod,
      shippingDate: formData.shippingDate,
      comment: formData.comment,
      bigMoney: formData.bigMoney,
      requestInvoice: formData.requestInvoice,
      isPrivate: formData.isPrivate,
      isPrivateName: formData.isPrivateName,
      billingCompany: formData.billingCompany,
      billingTaxNumber: formData.billingTaxNumber,
      billingAddress: formData.billingAddress,
      usedLoyaltyPoints: 0,
    };

    reduxProps.CartActions.saveFormData(formDataToSave);
  }, [formData, reduxProps.CartActions]);

  // Set coupon code if its in the query

  useEffect(() => {
    const urlCouponQuery = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlCouponQuery.entries());
    if (params?.withCoupon) {
      setFormData({ ...formData, coupon: params.withCoupon });
    }
    if (!openHoursSate.orderEnabled) {
      updateShippingMethod(IOrderShippingMethod.AtTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoyaltyPoints(0);
  }, [initialPrice]);

  useEffect(() => {
    if (reduxProps.userIsLoggedIn) {
      reduxProps.AuthActions.getUserInfo();
    }
    reduxProps.OrderActions.fetchShippingCosts()
      .then((resp) => {
        setShippingCosts({
          kecskemet: null,
          kecskemet_to_else: resp.data.kecskemetToElse,
          szolnok: null,
          szolnok_to_else: resp.data.szolnokToElse,
        });
      })
      .catch((error: Error) => {
        toast({
          title: 'Hiba',
          description: error.message,
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
      });

    const cartSize = correctCartItems.reduce(
      (acc, currVal) => acc + currVal.quantity,
      0
    );
    if (
      cartSize > 0 &&
      (couponData || formData.coupon || reduxProps.usedCoupon)
    ) {
      checkCoupon();
    } else {
      onCouponDataChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxProps.OrderActions, toast, correctCartItems]);

  function openDrinkPopup() {
    if (!wasTriggered) {
      setPopupWhatsOpen('order');
      setPopupOpen(true);
    }
    setWasTriggered(true);
  }

  function openPopups() {
    openDrinkPopup();
    return false;
  }
  const isCardValid = () =>
    !(formData.paymentType === 'CARD' && !isCardAccepted);

  function validateFormData() {
    if (formData.county === '') {
      throw new Error('Kérlek adj meg egy megyét!');
    }
    if (!isCardValid()) {
      setInvalidateCardAccepted(true);
      throw new Error('Kérlek fogadd el a bankártyás fizetés feltételeit');
    }
    if (formData.name === '') {
      throw new Error('Nem találtunk nevet, megadtad?');
    }
    if (formData.email === '') {
      throw new Error('Nem találtunk email címet, megadtad?');
    }
    if (formData.isPrivate && formData.isPrivateName === '') {
      throw new Error('Kérjük töltsd ki helyesen a számlázási adatokat!');
    }
    if (formData.isPrivate && formData.billingAddress === '') {
      throw new Error('Kérjük töltsd ki helyesen a számlázási adatokat!');
    }
    if (
      formData.requestInvoice &&
      formData.billingAddress === '' &&
      !formData.isPrivate
    ) {
      throw new Error('Kérjük töltsd ki helyesen a számlázási adatokat!');
    }
    if (
      formData.requestInvoice &&
      formData.billingCompany === '' &&
      !formData.isPrivate
    ) {
      throw new Error('Kérjük töltsd ki helyesen a számlázási adatokat!');
    }
    if (
      formData.requestInvoice &&
      formData.billingTaxNumber === '' &&
      !formData.isPrivate
    ) {
      throw new Error('Kérjük töltsd ki helyesen a számlázási adatokat!');
    }
    if (!formData.paymentType) {
      throw new Error('Kérlek válassz fizetési módot!');
    }
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (formData.phone === '' || !phoneRegex.test(formData.phone)) {
      throw new Error('A megadott telefonszám nem megfelelő');
    }
  }

  const getShippingCost = (): number => {
    if (selectedAddress.zip === 5000 || selectedAddress.zip === 5008) {
      const data = szolnokData;
      const findAdd = data.find(
        (address) =>
          (addressFormData.street || selectedAddress.street) === address.name
      );

      let cost;
      switch (findAdd?.category) {
        case 'a':
          cost = 390;
          break;
        case 'b':
          cost = 590;
          break;
        case 'c':
          cost = 790;
          break;
        default:
          cost = 0;
      }

      if (correctCartItems.some((item) => item.product.isVoucher)) {
        cost = 0;
      }

      return cost;
    }
    if (selectedAddress.zip === 6000) {
      const data = kecskemetData;
      const findAdd = data.find(
        (address) =>
          (addressFormData.street || selectedAddress.street) === address.name
      );

      let cost;
      switch (findAdd?.category) {
        case 'a':
          cost = 190;
          break;
        case 'b':
          cost = 390;
          break;
        case 'c':
          cost = 590;
          break;
        case 'd':
          cost = 790;
          break;
        case 'e':
          cost = 1090;
          break;
        default:
          cost = 0;
      }
      return cost;
    }
    if (
      selectedAddress.zip === 5051 ||
      selectedAddress.zip === 5071 ||
      selectedAddress.zip === 5081 ||
      selectedAddress.zip === 5085 ||
      selectedAddress.zip === 5091
    ) {
      return shippingCosts.szolnok_to_else;
    }
    return 0;
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [openHoursSate, setOpenHoursState] = useState(getOpenHoursState());

  function gtagEvent() {
    //@ts-ignore
    window.gtag('event', 'conversion', {
      send_to: 'AW-653086677/AWD-CM_K8sADENWftbcC',
      value: totalCartPrice,
      currency: 'HUF',
      transaction_id: '',
    });
  }

  async function order() {
    const openState = getOpenHoursState();
    setOpenHoursState(openState);
    if (
      !openState.orderEnabled &&
      formData.shippingMethod !== IOrderShippingMethod.AtTime
    ) {
      toast({
        title: 'Hiba',
        description:
          'Az étterem bezárt, látogass vissza holnap vagy válaszd a "Kiszállítás megadott időpontra" opciót',
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    try {
      if (openPopups()) return;
      if (formData.email === '' || formData.name === '' || !isAfszAccepted) {
        onOpen();
        return;
      }

      try {
        validateFormData();
      } catch (error) {
        showErrorToast(error);
        return;
      }

      let wasError = false;

      await reduxProps.OrderActions.orderCart(
        correctCartItems,
        cityId?.id ?? -1,
        selectedAddress,
        formData
      ).catch((error) => {
        wasError = true;
        toast({
          title: 'Hiba',
          description:
            error?.response?.data?.msg ?? 'Hiba történt a rendelés során!',
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
      });

      if (!wasError) {
        reduxProps.CartActions.emptyCart();
        toast({
          title: 'Sikeres rendelés',
          description: '',
          status: 'success',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
        onCouponDataChange(undefined);
        router(`/${city}/success`);
        gtagEvent();
      }
    } finally {
      setLoading(false);
    }
  }

  const addNewAddress = () => {
    setAddressModification(false);
    setNewAddress(true);
    setAddressFormData(defaultAddressFormData);
  };

  const handleAddressSubmit = async () => {
    if (addressFormData.zip < 1000 || addressFormData.zip > 9999) {
      toast({
        title: 'Hiba',
        description: 'A megadott irányítószám nem helyes!',
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // If the user is logged in: add on network, else on state
    if (reduxProps.userIsLoggedIn) {
      let currentAddresses: IAddress[] = [];
      currentAddresses = reduxProps.userInfo?.addresses ?? [];
      const addedAddresses = [addressFormData, ...currentAddresses];
      const promise = reduxProps.AuthActions.addAddress(addedAddresses);

      await promise.then(() => {
        // Get the new user data
        reduxProps.AuthActions.getUserInfo();
      });
    } else {
      reduxProps.AddressActions.addAddress(addressFormData);
      setAddressFormData(addressFormData);
    }

    setNewAddress(false);
    setAddressModification(false);
  };

  const handleAddressModifSubmit = async () => {
    // If the user is logged in: modification on network, else on state
    if (reduxProps.userIsLoggedIn) {
      let currentAddresses: IAddress[] = [];
      currentAddresses =
        reduxProps.userInfo?.addresses?.filter((x) => {
          return x !== oldAddressData;
        }) ?? [];
      const addedAddresses = [addressFormData, ...currentAddresses];
      const promise = reduxProps.AuthActions.addAddress(addedAddresses);

      await promise.then(() => {
        // Get the new user data
        reduxProps.AuthActions.getUserInfo();
      });
    } else {
      reduxProps.AddressActions.removeAddress(oldAddressData);
      reduxProps.AddressActions.addAddress(addressFormData);
    }

    setNewAddress(false);
    setAddressModification(false);
  };

  const handleAddressRemove = (address: IAddress) => {
    reduxProps.AddressActions.removeAddress(address);
  };

  const handleAddressModification = (address: IAddress) => {
    setAddressFormData(address);
    setOldAddress(address);
    setAddressModification(true);
    setNewAddress(true);
  };

  const handleAddressSelection = (address: IAddress) => {
    setSelectedAddress(address);
  };
  useEffect(() => {
    const addresses =
      reduxProps.userInfo?.addresses ?? reduxProps.localAddresses ?? [];
    setSelectedAddress(addresses[0] ?? defaultAddressFormData);
    // eslint-disable-next-line
  }, [reduxProps.userInfo?.addresses, reduxProps.localAddresses]);

  const saveNameAndEmail = async () => {
    if (reduxProps.userIsLoggedIn && formData.name && formData.email) {
      await reduxProps.AuthActions.modifyInfo({
        ...(reduxProps.userInfo as RegisterFormData),
        fullName: formData.name,
      }).catch((error) => {
        toast({
          title: 'Hiba',
          description: error?.response?.data?.msg,
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
      });
    }
  };
  const company: string =
    city === CITY_ID[0].name ? 'forno' : 'pastaline' ?? '';

  const handleDateChange = (dat: any) => {
    const selectedNewDateOfWeek = moment(dat).get('day');
    const contrs = getTimeConstraints(selectedNewDateOfWeek)[0];

    setFormData({
      ...formData,
      shippingDate: moment(dat).set({ hour: contrs.hour, minute: contrs.min }),
    });
  };
  const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const hourAndMin = e.target.value.split(':');
    setFormData({
      ...formData,
      shippingDate: formData.shippingDate.set({
        hour: Number(hourAndMin[0]),
        minute: Number(hourAndMin[1]),
      }),
    });
  };

  /**
   * If shipping method is pickup change the payment type to card, because it's the only valid option
   * for this type of shipment
   * @param shippingMethod
   */
  const updateShippingMethod = (shippingMethod: IOrderShippingMethod): void => {
    let paymentType =
      shippingMethod === IOrderShippingMethod.Pickup
        ? 'CARD'
        : formData.paymentType;
    const selectedNewDateOfWeek = moment().get('day');
    const contrs = getTimeConstraints(selectedNewDateOfWeek)[0];

    setFormData({
      ...formData,
      shippingMethod: shippingMethod,
      shippingDate: moment().set({ hour: contrs?.hour, minute: contrs?.min }),
      paymentType,
    });
  };

  const minimumOrderValue = 2190;

  const totalCartPrice =
    initialPrice - loyaltyPoints - (couponData?.discount ?? 0);

  const activatePoints = () => {
    const points = reduxProps.userInfo.loyaltyPoint;
    let discountPrice;
    if (points > initialPrice) {
      discountPrice = initialPrice;
    } else {
      discountPrice = points;
    }

    if (totalCartPrice < discountPrice) {
      discountPrice = totalCartPrice;
    }
    toast({
      title: 'Siker',
      description: `Felhasználtál ${discountPrice} Ft értékű hűségpontot!`,
      status: 'success',
      position: 'top',
      duration: 3000,
      isClosable: true,
    });
    setLoyaltyPoints(discountPrice);
    setFormData({ ...formData, usedLoyaltyPoints: discountPrice });
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius='8px'>
          <ModalHeader>
            {reduxProps.userInfo
              ? 'Az alábbi adatok hiányoznak a profilodból, '
              : 'Ha regisztráció nélkül akarsz rendelni, '}
            <br />
            {reduxProps.userInfo
              ? 'kérjük add meg őket.'
              : 'kérjük add meg a hiányzó adatokat és fogadd el az Általános Szerződési Feltételeket!'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={2}>
              <Heading size='sm' as='h4'>
                Név:
              </Heading>
              <Input
                backgroundColor='gray.300'
                placeholder='név'
                value={formData.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({ ...formData, name: e.target.value ?? '' });
                }}
              />
              <Heading size='sm' as='h4' mt={2}>
                E-mail:
              </Heading>
              <Input
                backgroundColor='gray.300'
                placeholder='e-mail cím'
                value={formData.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({ ...formData, email: e.target.value ?? '' });
                }}
              />
              <Box gridColumn='colStart / colEnd'>
                <Checkbox
                  isInvalid={invalidateASZF}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAszf(e.target.checked);
                  }}
                >
                  <a href={`/doc/szerzodesi-feltetelek-${company}.pdf`}>
                    Elolvastam és elfogadom az Általános Szerződési Feltételeket
                  </a>
                </Checkbox>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variantColor='green'
              mr={3}
              onClick={() => {
                if (!isAfszAccepted) {
                  toast({
                    title: 'Hiba',
                    description:
                      'Kérjük fogadd el az Általános Szerőzdési Feltételeket!',
                    status: 'error',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                  });

                  setInvalidateAFSZ(true);

                  return;
                }
                saveNameAndEmail();
                onClose();
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div>
        {addingAddress && (
          <Box>
            <Card color='black' textAlign='right' mb={1}>
              <IconButton
                mb={2}
                icon='close'
                size='sm'
                variant='outline'
                aria-label='Mégse'
                color='red.300'
                isRound
                onClick={() => {
                  setAddressModification(false);
                  setNewAddress(false);
                }}
              />
              <FormControl>
                <Grid
                  gridTemplateColumns='[colStart] 1fr 1fr [colEnd]'
                  w='100%'
                  gridGap={2}
                >
                  <Input
                    gridColumn='colStart / colEnd'
                    type='text'
                    placeholder='Név'
                    size='sm'
                    backgroundColor='gray.300'
                    _placeholder={{ color: 'black' }}
                    value={addressFormData.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressFormData({
                        ...addressFormData,
                        name: e.target.value,
                      });
                    }}
                  />
                  <Input
                    gridColumn='colStart / colEnd'
                    type='number'
                    placeholder='Irányítószám'
                    size='sm'
                    backgroundColor='gray.300'
                    _placeholder={{ color: 'black' }}
                    value={addressFormData.zip === 0 ? '' : addressFormData.zip}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressFormData({
                        ...addressFormData,
                        zip: e.target.valueAsNumber,
                      });
                    }}
                  />
                  <Input
                    gridColumn='colStart / colEnd'
                    type='text'
                    placeholder='Város'
                    size='sm'
                    backgroundColor='gray.300'
                    _placeholder={{ color: 'black' }}
                    value={addressFormData.city}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressFormData({
                        ...addressFormData,
                        city: e.target.value,
                      });
                    }}
                  />
                  <Autocomplete
                    setIsAddressSelected={setIsAddressSelected}
                    suggestions={
                      city === CITY_ID[0].name ? szolnokData : kecskemetData
                    }
                    setData={setAddressFormData}
                    data={addressFormData}
                    isDisabled={addressFormData.zip.toString().length !== 4}
                  />
                  <Input
                    gridColumn='colStart / colEnd'
                    type='text'
                    placeholder='Házszám'
                    size='sm'
                    backgroundColor='gray.300'
                    _placeholder={{ color: 'black' }}
                    value={addressFormData.number}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressFormData({
                        ...addressFormData,
                        number: e.target.value,
                      });
                    }}
                  />
                  <Input
                    gridColumn='colStart / colEnd'
                    type='text'
                    placeholder='Emelet/ajtó'
                    size='sm'
                    backgroundColor='gray.300'
                    _placeholder={{ color: 'black' }}
                    value={addressFormData.floorDoor}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressFormData({
                        ...addressFormData,
                        floorDoor: e.target.value,
                      });
                    }}
                  />
                  <Input
                    gridColumn='colStart / colEnd'
                    type='text'
                    placeholder='Csengő/megjegyzés'
                    size='sm'
                    backgroundColor='gray.300'
                    _placeholder={{ color: 'black' }}
                    value={addressFormData.bellComment}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressFormData({
                        ...addressFormData,
                        bellComment: e.target.value,
                      });
                    }}
                  />
                  <Box gridColumn='colStart / colEnd' w={[289.22, 286]} />
                  {modifyAddress ? (
                    <Button
                      gridColumn='colStart / colEnd'
                      variantColor='blue'
                      onClick={handleAddressModifSubmit}
                      isDisabled={
                        !isAddressSelected &&
                        (addressFormData.zip === 5000 ||
                          addressFormData.zip === 5008 ||
                          addressFormData.zip === 6000)
                      }
                    >
                      Cím módosítása
                    </Button>
                  ) : (
                    <Button
                      gridColumn='colStart / colEnd'
                      variantColor='green'
                      onClick={handleAddressSubmit}
                      isDisabled={
                        !isAddressSelected &&
                        (addressFormData.zip === 5000 ||
                          addressFormData.zip === 5008 ||
                          addressFormData.zip === 6000)
                      }
                    >
                      Új cím mentése
                    </Button>
                  )}
                </Grid>
              </FormControl>
            </Card>
          </Box>
        )}
        <Card>
          <Grid
            gap={4}
            gridTemplateColumns={[
              '[colStart col2 colEnd] 1fr',
              '[colStart] 7fr [col2] 3fr [colEnd]',
              '[colStart] 3fr [col2] 1fr [colEnd]',
              '[colStart] 3fr [col2] 2fr [colEnd]',
            ]}
          >
            <Heading gridColumn='colStart / colEnd' size='sm' as='h4'>
              Szállítási címem <br></br> (Éttermi átvétel esetén is ki kell
              tölteni!)
            </Heading>

            {
              // eslint-disable-next-line no-nested-ternary
              reduxProps.userIsLoggedIn
                ? reduxProps.userInfo?.addresses?.map((address) => {
                    return (
                      <React.Fragment key={address.zip + address.number}>
                        <Text
                          gridColumn='colStart / col2'
                          alignSelf='center'
                          p={1}
                          border={
                            selectedAddress === address
                              ? '1.5px solid #48bb78'
                              : '0px solid white'
                          }
                          borderRadius='5px'
                        >
                          {address.zip} {address.street} {address.number}{' '}
                          {address.floorDoor}
                          <br />
                          {address.bellComment}
                        </Text>
                        <Flex
                          gridColumn='col2 / colEnd'
                          justifyContent='flex-end'
                        >
                          <Button
                            variantColor='green'
                            onClick={() => {
                              handleAddressSelection(address);
                            }}
                          >
                            Ide kérem
                          </Button>
                        </Flex>
                      </React.Fragment>
                    );
                  })
                : reduxProps.localAddresses?.map((address) => {
                    return (
                      <React.Fragment key={address.zip + address.number}>
                        <Text
                          gridColumn='colStart / col2'
                          alignSelf='center'
                          p={1}
                          border={
                            selectedAddress === address
                              ? '1.5px solid #48bb78'
                              : '0px solid white'
                          }
                          borderRadius='5px'
                        >
                          {address.zip} {address.street} {address.number}{' '}
                          {address.floorDoor}
                          <br />
                          {address.bellComment}
                        </Text>
                        <Flex
                          gridColumn='col2 / colEnd'
                          justifyContent='flex-end'
                        >
                          <IconButton
                            mr={2}
                            icon='delete'
                            size='sm'
                            variant='ghost'
                            variantColor='red'
                            aria-label='Törlés'
                            isDisabled
                            onClick={() => {
                              handleAddressRemove(address);
                            }}
                          />
                          <IconButton
                            mr={2}
                            icon='edit'
                            size='sm'
                            variant='ghost'
                            aria-label='Szerkesztés'
                            onClick={() => {
                              handleAddressModification(address);
                            }}
                          />

                          <IconButton
                            icon='check-circle'
                            size='sm'
                            variant='ghost'
                            color='green.400'
                            aria-label='Kiválasztás'
                            onClick={() => {
                              handleAddressSelection(address);
                            }}
                          />
                        </Flex>
                      </React.Fragment>
                    );
                  })
            }
            <Button
              gridColumn={['colStart']}
              variantColor='gray'
              leftIcon='add'
              size='sm'
              onClick={addNewAddress}
            >
              Új cím
            </Button>

            <Heading gridColumn='colStart / colEnd' size='sm' as='h4'>
              Telefonszám{' '}
              <Text fontSize='sm' as='i' fontWeight='initial'>
                (0620xxxxxxx formában)
              </Text>
            </Heading>
            <Input
              gridColumn='colStart / colEnd'
              type='tel'
              backgroundColor='gray.300'
              placeholder='Telefonszám'
              value={formData.phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, phone: e.target.value });
              }}
            />
            <Heading gridColumn='colStart / colEnd' size='sm' as='h4'>
              Megjegyzés
            </Heading>
            <Textarea
              gridColumn='colStart / colEnd'
              backgroundColor='gray.300'
              value={formData.comment}
              placeholder='Megjegyzés'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, comment: e.target.value });
              }}
            />
            <Box gridColumn='colStart / colEnd'>
              <Select
                backgroundColor='gray.300'
                defaultValue={IOrderShippingMethod.Instant}
                value={formData.shippingMethod}
                onChange={(e) => {
                  updateShippingMethod(e.target.value as IOrderShippingMethod);
                }}
              >
                <option value='' disabled>
                  Válassz átvételi módot
                </option>

                {openHoursSate.orderEnabled && (
                  <option value={IOrderShippingMethod.Instant}>
                    Kiszállítás azonnal
                  </option>
                )}

                <option value={IOrderShippingMethod.AtTime}>
                  Kiszállítás megadott időpontra
                </option>

                {openHoursSate.orderEnabled && (
                  <option value={IOrderShippingMethod.Pickup}>
                    Személyes átvétel: {cityId?.address}
                  </option>
                )}
              </Select>
            </Box>

            {formData.shippingMethod === IOrderShippingMethod.AtTime && (
              <>
                <DatePicker
                  clearIcon={null}
                  value={formData.shippingDate.toDate()}
                  onChange={handleDateChange}
                  required
                  minDate={new Date()}
                  maxDate={addDays(6)}
                  locale='hu-HU'
                />
                <Select
                  value={`${formData.shippingDate.get(
                    'hour'
                  )}:${formData.shippingDate.get('minute')}`}
                  gridColumn={['colStart', 'col2']}
                  backgroundColor='gray.300'
                  onChange={handleHourChange}
                >
                  {getTimeConstraints(
                    moment(formData.shippingDate).get('day')
                  )?.map(({ hour, min }) => (
                    <option value={`${hour}:${min}`}>
                      {hour}:{min === 0 ? '00' : min}
                    </option>
                  ))}
                </Select>
              </>
            )}

            <Box gridColumn='colStart / colEnd'>
              <Select
                backgroundColor='gray.300'
                defaultValue=''
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (
                    e.target.value === 'CASH' ||
                    e.target.value === 'CARD' ||
                    e.target.value === 'CARD_LOCATION' ||
                    e.target.value === 'OTPDIRECT'
                  )
                    setFormData({
                      ...formData,
                      paymentType: e.target.value,
                    });
                }}
                value={formData.paymentType}
              >
                <option value='' disabled>
                  Válassz fizetési módot
                </option>

                <option value='CARD'>
                  Fizetés azonnal bankkártyával(Visa, MasterCard)
                </option>

                <option value='OTPDIRECT'>
                  Fizetés OTP szép kártyával azonnal
                </option>
                <option value='CARD_LOCATION'>
                  Fizetés bankkártyával vagy szép kártyával átvételkor
                </option>

                {formData.shippingMethod !== IOrderShippingMethod.Pickup && (
                  <>
                    <option value='CASH'>Fizetés helyszínen készpénzzel</option>
                  </>
                )}
              </Select>
            </Box>
            <Box
              pl={[4, 0]}
              gridColumn='colStart / colEnd'
              display={formData.paymentType === 'CASH' ? 'inherit' : 'none'}
            >
              <Checkbox
                isChecked={formData.bigMoney}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    bigMoney: e.target.checked,
                  });
                }}
              >
                Nagy címlettel fizetek
              </Checkbox>
            </Box>

            <Box pl={[5, 0]} gridColumn='colStart / colEnd'>
              <Checkbox
                isChecked={formData.requestInvoice}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    requestInvoice: e.target.checked,
                  });
                }}
              >
                Számlát kérek
              </Checkbox>
            </Box>

            {formData.requestInvoice && (
              <>
                <Checkbox
                  isChecked={formData.isPrivate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({
                      ...formData,
                      isPrivate: e.target.checked,
                    });
                  }}
                >
                  Magánszemély vagyok
                </Checkbox>

                {!formData.isPrivate && (
                  <>
                    <Input
                      backgroundColor='gray.300'
                      placeholder='Cégnév'
                      gridColumn='colStart'
                      value={formData.billingCompany}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({
                          ...formData,
                          billingCompany: e.target.value,
                        });
                      }}
                    />
                    <Input
                      backgroundColor='gray.300'
                      placeholder='Adószám'
                      value={formData.billingTaxNumber}
                      gridColumn={['colStart', 'col2']}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({
                          ...formData,
                          billingTaxNumber: e.target.value,
                        });
                      }}
                    />
                  </>
                )}
                {formData.isPrivate && (
                  <>
                    <Input
                      backgroundColor='gray.300'
                      placeholder='Név'
                      value={formData.isPrivateName}
                      gridColumn='colStart / colEnd'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({
                          ...formData,
                          isPrivateName: e.target.value,
                        });
                      }}
                    />
                  </>
                )}
                <Box gridColumn='colStart / colEnd'>
                  <Input
                    backgroundColor='gray.300'
                    placeholder='Cím'
                    value={formData.billingAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormData({
                        ...formData,
                        billingAddress: e.target.value,
                      });
                    }}
                  />
                </Box>
                <Divider
                  borderColor='gray.400'
                  gridColumn='colStart / colEnd'
                />
              </>
            )}
            {!loyaltyPoints && (
              <>
                <Heading
                  gridColumn='colStart / colEnd'
                  size='sm'
                  as='h4'
                  mr={1}
                >
                  Van kuponod?
                </Heading>
                <Input
                  gridColumn='colStart'
                  backgroundColor='gray.300'
                  placeholder='Kuponkód'
                  value={formData.coupon}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, coupon: e.target.value });
                  }}
                />
                <Button
                  gridColumn={['colStart', 'col2']}
                  variantColor='red'
                  onClick={checkCoupon}
                >
                  Aktiválás
                </Button>
              </>
            )}
            {reduxProps.userIsLoggedIn && !couponData?.discount && (
              <>
                <Heading
                  gridColumn='colStart / colEnd'
                  textAlign='center'
                  size='sm'
                  as='h4'
                  mr={1}
                >
                  Hűségpontjaim: {reduxProps.userInfo?.loyaltyPoint}
                </Heading>

                {reduxProps.userInfo?.loyaltyPoint !== 0 && (
                  <Button
                    gridColumn='colStart / colEnd'
                    variantColor='red'
                    onClick={activatePoints}
                  >
                    Beváltás
                  </Button>
                )}
              </>
            )}

            <Divider borderColor='gray.400' gridColumn='colStart / colEnd' />
          </Grid>
          <Grid
            mt={4}
            gap={4}
            gridTemplateColumns={[
              '[colStart col2 colEnd] 1fr',
              '[colStart] 1fr [col2] 1fr [colEnd]',
              '[colStart] 3fr [col2] 2fr [colEnd]',
              '[colStart] 2fr [col2] 3fr [colEnd]',
            ]}
          >
            <Heading gridColumn='colStart / col2' size='sm' as='h4'>
              Összegzés
            </Heading>
            <Flex gridColumn='col2 / colEnd' pl={[4, 0]} position='relative'>
              <Text>Termékek:</Text>
              <Text position='absolute' right={0}>
                {initialPrice} Ft
              </Text>
            </Flex>
            <Flex gridColumn='col2 / colEnd' pl={[4, 0]} position='relative'>
              {loyaltyPoints === 0 ? null : (
                <>
                  <Text>Hűségpont:</Text>
                  <Text position='absolute' right={0}>
                    - {loyaltyPoints} Ft
                  </Text>
                </>
              )}
            </Flex>
            {couponData && (
              <>
                <Flex
                  gridColumn='col2 / colEnd'
                  pl={[4, 0]}
                  position='relative'
                >
                  <Text>Felhasznált kupon:</Text>
                </Flex>
                <Flex
                  gridColumn='col2 / colEnd'
                  pl={[3, 0]}
                  position='relative'
                >
                  <Text position='relative' right={0}>
                    <li>
                      <i> {couponData?.name}</i>
                    </li>
                  </Text>
                </Flex>
                <Flex
                  gridColumn='col2 / colEnd'
                  pl={[4, 0]}
                  position='relative'
                >
                  <Text>Kedvezmény: </Text>
                  <Text position='absolute' right={0}>
                    {` - ${couponData?.discount}`} Ft
                  </Text>
                </Flex>
              </>
            )}
            <Flex gridColumn='col2 / colEnd' pl={[4, 0]} position='relative'>
              {getShippingCost() === 0 ? (
                <Text>Díjtalan kiszállítás</Text>
              ) : (
                <>
                  <Text>Kiszállítási díj:</Text>
                  <Text position='absolute' right={0}>
                    + {getShippingCost()} Ft
                  </Text>
                </>
              )}
            </Flex>

            <Divider m={0} borderColor='gray.400' gridColumn='col2 / colEnd' />
            <Flex gridColumn='col2 / colEnd' pl={[4, 0]} position='relative'>
              <Text>Összesen:</Text>
              <Text position='absolute' right={0}>
                {totalCartPrice + getShippingCost()}
                Ft
              </Text>
            </Flex>
            <Button
              variantColor='green'
              gridColumn='col2 / colEnd'
              rightIcon={
                isLoading || initialPrice < minimumOrderValue
                  ? undefined
                  : 'arrow-forward'
              }
              onClick={order}
              isDisabled={isLoading || initialPrice <= minimumOrderValue}
              onMouseEnter={openDrinkPopup}
            >
              {isLoading
                ? 'Rendelés...'
                : initialPrice < minimumOrderValue
                ? `Min. érték: ${minimumOrderValue} Ft`
                : 'Megrendelem'}
            </Button>
          </Grid>
          {formData.paymentType === 'CARD' && (
            <Text style={{ fontSize: 11, textAlign: 'justify', marginTop: 5 }}>
              {city === 'szolnok'
                ? configFile.cardDisclaimerSzolnok
                : configFile.cardDisclaimerKecskemet}
              <a href='http://simplepay.hu/vasarlo-aff'>
                http://simplepay.hu/vasarlo-aff
              </a>
            </Text>
          )}
        </Card>
      </div>
    </>
  );
}

export default CartSummary;
